<ng-template let-modal #modaPlanInvalid>
    <div class="modal-body p-0">
        <div class="warning-message text-center text-purple">{{ plansService.titlePlanInvalid$ | async }}</div>
        <div class="warning-message text-center">{{ plansService.messagePlanInvalid$ | async }}</div>
    </div>
    <div class="modal-footer border-top-0 mt-5">
        <div class="box-btn-cancel">
            <button class="btn btn-confirm btn-purple" type="button" (click)="goTo('/dashboard')">Ir al Home</button>
        </div>
        <div class="box-btn-cancel">
            <button class="btn btn-cancel" type="button" (click)="goTo('/comercio/plans')">Mejorar plan</button>
        </div>
    </div>
</ng-template>
