import { Component } from '@angular/core';

@Component({
    selector: 'fina-blank',
    templateUrl: './blank.component.html',
    styleUrls: ['blank.component.scss'],
})
export class BlankComponent {
    constructor() {}
}
