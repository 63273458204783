<nav class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" aria-label="Top navigation">
    <button class="btn btn-icon btn-transparent-dark ms-2" id="sidebarToggle" data-cy="topNavToggleSideNav" (click)="toggleSideNav()">
        <i-feather name="menu"></i-feather>
    </button>
    <a class="navbar-brand px-2 ps-lg-2" routerLink="/dashboard">
        <img class="logo" src="assets/icons/logo.svg" alt="logo" />
    </a>
    <ul class="navbar-nav align-items-center ms-auto me-lg-4">
        <button
            *ngIf="(userService.user$ | async)?.permissions.POS.includes('open-direct-and-open-sales')"
            class="btn btn-new-sale me-2 me-lg-3 px-3 px-lg-5"
            routerLink="/pos/direct-sales"
            (click)="analyticsService.analyticsCapture('sale_opened sale', { lugar: 'top-nav' })"
        >
            <i-feather name="shopping-cart" class="me-1 me-lg-2 text-white"></i-feather>
            <span class="d-none d-sm-inline">Vender</span>
            <span class="d-sm-none">+</span>
        </button>
        <fina-form-fixed-variable-expenses
            *ngIf="(userService.user$ | async)?.permissions?.FINANCIAL_SUMMARY?.includes('create-edit-fixes-variables-expenses') && (plansService.planInvalid$ | async) === false"
            class="me-2 me-lg-3"
            [location]="'top-nav'"
            [compactMode]="isSmallScreen"
        ></fina-form-fixed-variable-expenses>
        <sbpro-top-nav-user></sbpro-top-nav-user>
    </ul>
</nav>
