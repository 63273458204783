import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BankAccount } from '@common/models';
import { FixedVariableExpense } from '@common/models/fixed-variable-expense.model';
import { AnalyticsService, BankAccountsService, CommerceService, FixedVariableExpensesService, ToastService } from '@common/services';
import { trackEvent } from '@intercom/messenger-js-sdk';
import { UserService } from '@modules/auth/services';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { firstValueFrom, Subscription } from 'rxjs';

const initialDataForm: FixedVariableExpense = {
    _id: '',
    name: '',
    amount: '',
    type: 'variable',
    payDays: '',
    lastPaymentDay: null,
    paymentDate: null,
    registryDate: null,
    paymentType: '',
    selectedBankAccount: '',
    paymentFrequency: 'mensual',
    exchangeRate: '',
    description: '',
    amountVES: Number(0),
};

interface BankAccountData {
    selectedBankAccount: string;
    currency: string;
    exchangeRate: number;
    bankAccountVESPayment: number;
    bankAccountUSDPayment: number;
}

const initialBankAccountData: BankAccountData = {
    selectedBankAccount: '',
    currency: '',
    exchangeRate: 0,
    bankAccountVESPayment: 0,
    bankAccountUSDPayment: 0,
};

@Component({
    selector: 'fina-form-fixed-variable-expenses',
    templateUrl: './form-fixed-variable-expenses.component.html',
    styleUrls: ['form-fixed-variable-expenses.component.scss'],
})
export class FormFixedVariableExpensesComponent implements OnInit, OnDestroy {
    @Input() dataToUpdate: FixedVariableExpense = null;
    @Input() location: string = '';
    @Input() compactMode: boolean = false;
    formData: FixedVariableExpense = { ...initialDataForm };
    bankAccountData: BankAccountData = { ...initialBankAccountData };
    bankAccounts: BankAccount[] = [];
    selectedBankAccount: BankAccount = null;
    currentDate: { year: number; month: number; day: number };
    minDate: { year: number; month: number; day: number };

    loading: boolean = false;
    exchangeRate: number = Number(0);

    private subscriptions: Subscription[] = [];

    constructor(
        public analyticsService: AnalyticsService,
        public bankAccountsService: BankAccountsService,
        private commerceService: CommerceService,
        public fveService: FixedVariableExpensesService,
        private modalService: NgbModal,
        private toastService: ToastService,
        public userService: UserService,
    ) {}

    ngOnInit() {
        this.getExchangeRate();
        this.currentDate = this.getCurrentDate();
        this.getMinDate();
        this.getBankAccounts();
        this.subscribeToBankAccounts();

        if (this.dataToUpdate) {
            this.completeFormToUpdate();
        } else {
            this.formData.registryDate = this.getCurrentDate();
        }
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions to prevent memory leaks
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    completeFormToUpdate() {
        this.formData = { ...this.dataToUpdate };

        if (this.dataToUpdate.paymentDate) {
            const date = this.dataToUpdate.paymentDate;
            this.formData.paymentDate = {
                year: Number(moment(date.toString()).format('YYYY')),
                month: Number(moment(date.toString()).format('M')),
                day: Number(moment(date.toString()).format('D')),
            };
        }

        this.onChangeBankAccount();
    }

    onChangeType(type: string) {
        this.formData = { ...this.formData, type };
    }

    onChangeExchangeRateBCV() {
        this.formData = {
            ...this.formData,
            amountVES: Number((Number(this.formData.amount) * Number(this.formData.exchangeRate)).toFixed(2)),
        };
    }

    save() {
        if (this.dataToUpdate) {
            this.onUpdateFVE();
            trackEvent('update_expenses');
        } else {
            this.onAddFVE();
            trackEvent('add_expenses');
        }
    }

    delete() {
        this.loading = true;
        this.fveService.deleteFixedVariableExpense$(this.dataToUpdate._id).subscribe({
            next: (res: any) => {
                this.setAfterSuccess(res);
            },
            error: (err: any) => {
                this.setAfterError(err);
            },
        });
    }

    onUpdateFVE() {
        this.loading = true;
        this.fveService.updateFixedVariableExpense$(this.dataToUpdate._id, this.formData, this.bankAccountData).subscribe({
            next: (res: any) => {
                this.setAfterSuccess(res);
            },
            error: (err: any) => {
                this.setAfterError(err);
            },
        });
    }

    onAddFVE() {
        this.loading = true;
        this.fveService.createFixedVariableExpense$(this.formData, this.bankAccountData).subscribe({
            next: (res: any) => {
                this.setAfterSuccess(res);
            },
            error: (err: any) => {
                this.setAfterError(err);
            },
        });
    }

    onChangeBankAccount() {
        this.bankAccountData.bankAccountUSDPayment = Number(this.formData.amount);

        if (!this.bankAccountData.selectedBankAccount || this.bankAccounts.length === 0) {
            this.selectedBankAccount = null;
            this.bankAccountData = { ...initialBankAccountData };
            return;
        }

        this.selectedBankAccount = this.bankAccounts.find(ba => ba._id === this.bankAccountData.selectedBankAccount);
        this.bankAccountData.currency = this.selectedBankAccount.currency.toString();

        if (this.bankAccountData.currency === 'VES') {
            this.bankAccountData.exchangeRate = Number(this.commerceService.exchangeRateInUse$.value.toFixed(2));
            this.onChangeExchangeRate();
        }
    }

    onChangeAmount() {
        const amount = Number(this.formData.amount) || 0;
        this.bankAccountData.bankAccountUSDPayment = amount;
        this.bankAccountData.bankAccountVESPayment = this.bankAccountData.exchangeRate > 0 ? Number((amount * this.bankAccountData.exchangeRate).toFixed(2)) : 0;
    }

    onChangeExchangeRate() {
        const amount = Number(this.formData.amount) || 0;
        const exchangeRate = this.bankAccountData.exchangeRate || 0;
        this.bankAccountData.bankAccountVESPayment = exchangeRate && amount ? Number((amount * exchangeRate).toFixed(2)) : 0;
    }

    isBCVRate() {
        return Number(this.bankAccountData.exchangeRate) === Number(this.commerceService.exchangeRateBCV.toFixed(2));
    }

    isBankAccountInvalid(): boolean {
        if (!this.formData.selectedBankAccount || !this.selectedBankAccount) {
            return false;
        }

        const balance = Number(this.selectedBankAccount.balance.toFixed(2));
        const payment = this.selectedBankAccount.currency === 'VES' ? Number(this.bankAccountData.bankAccountVESPayment.toFixed(2)) : Number(this.formData.amount || 0);

        return Number(payment.toFixed(2)) > balance;
    }

    delay(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    open(content: TemplateRef<any>, modalOptions: NgbModalOptions = {}) {
        this.modalService.open(content, modalOptions);
    }

    _getDismissReason(reason: unknown): string {
        this.formData = { ...initialDataForm };
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    private getBankAccounts() {
        firstValueFrom(this.bankAccountsService.getBankAccounts$())
            .then((res: any) => {
                this.bankAccounts = [...res.data];
            })
            .catch(err => {
                this.toastService.error(err.error.title, err.error.message);
            });
    }

    private subscribeToBankAccounts() {
        const subscription = this.bankAccountsService._bankAccounts$.subscribe({
            next: (res: any) => {
                this.bankAccounts = [...res];
            },
        });
        this.subscriptions.push(subscription);
    }

    private getCurrentDate(): { year: number; month: number; day: number } {
        const dateObject = new Date();
        const currentDate = {
            year: dateObject.getFullYear(),
            month: dateObject.getMonth() + 1,
            day: dateObject.getDate(),
        };
        return currentDate;
    }

    private getMinDate(): void {
        const subscription = this.commerceService._commerce$.subscribe({
            next: (res: any) => {
                if (res !== null && res !== undefined) {
                    const dateObject = new Date(res.createdAt);
                    const minDate = {
                        year: dateObject.getFullYear(),
                        month: dateObject.getMonth() + 1,
                        day: dateObject.getDate(),
                    };
                    this.minDate = { ...minDate };
                }
            },
        });
        this.subscriptions.push(subscription);
    }

    private getExchangeRate() {
        const subscription = this.commerceService.exchangeRateInUse$.subscribe({
            next: (value: number) => {
                this.formData.exchangeRate = value;
                this.bankAccountData.exchangeRate = value;
            },
        });
        this.subscriptions.push(subscription);
    }

    private setAfterSuccess(res: { title: string; message: string }): void {
        this.fveService.getFixedVariableExpenses();
        this.toastService.success(res.title, res.message);
        console.log('Toast service called with:', res.title, res.message);
        console.log('Toasts in service after adding:', this.toastService.toasts);
        (async () => {
            await this.delay(240);
            this.loading = false;
            this.modalService.dismissAll(true);
            this.formData = { ...initialDataForm };
        })();
    }

    private setAfterError(err: { error: { title: string; message: string } }): void {
        this.loading = false;
        this.toastService.error(err.error.title, err.error.message);
    }
}
