import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ExtraService } from '../../commerce/models';

@Injectable({
    providedIn: 'root',
})
export class ExtraServicesService {
    private _extraServices$: BehaviorSubject<ExtraService[]> = new BehaviorSubject<ExtraService[]>(null);
    private _extraServicesLoaded$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    isDifferent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isDifferentCommerce$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isDifferentES$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    API_URL = environment.API_URL;

    constructor(private http: HttpClient) {}

    get extraServices$() {
        return this._extraServices$;
    }

    get extraServicesLoaded$() {
        return this._extraServicesLoaded$;
    }

    set extraServices(array: ExtraService[]) {
        this._extraServices$.next([...array]);
    }

    set extraServicesLoaded(value: string) {
        this._extraServicesLoaded$.next(value);
    }

    getExtraServices$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/extra-services/`);
    }

    checkDifferenceES() {
        const isDifferent: boolean = JSON.stringify(this.extraServices$.getValue()) !== this.extraServicesLoaded$.getValue();
        this.isDifferentES$.next(isDifferent);
    }

    updateExtraServices$(): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/extra-services/save-changes`, {
            extraServices: [...this.extraServices$.getValue()],
        });
    }
}
