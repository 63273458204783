import { Component, Input } from '@angular/core';

@Component({
    selector: 'sbpro-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['avatar.component.scss'],
})
export class AvatarComponent {
    @Input() imageSrc!: string;
    @Input() classes!: string;

    constructor() {}
}
