<li
    class="nav-item dropdown no-caret me-3 me-lg-0 dropdown-user"
    *ngIf="userService.user$ | async as user"
    ngbDropdown
    [placement]="placement"
    display="dynamic"
>
    <a
        class="btn btn-icon btn-transparent-dark dropdown-toggle"
        id="navbarDropdownUserImage"
        ngbDropdownToggle
        data-cy="userMenu"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <fa-icon class="img-fluid" [icon]="['fas', 'user']" [styles]="{ 'font-size': '30px' }"></fa-icon>
    </a>

    <div class="dropdown-menu border-0 shadow animated--fade-in-up" ngbDropdownMenu aria-labelledby="dropdownUser">
        <h6 class="dropdown-header d-flex align-items-center">
            <div class="dropdown-user-img">
                <fa-icon [icon]="['fas', 'user']" [styles]="{ 'font-size': '40px' }"></fa-icon>
            </div>
            <div class="dropdown-user-details">
                <div class="dropdown-user-details-name">{{ user.name }} {{ user.lastName }}</div>
                <div class="dropdown-user-details-email">{{ user.email }}</div>
            </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" routerLink="/dashboard">
            <div class="dropdown-item-icon"><i-feather name="settings"></i-feather></div>
            Cuenta
        </a>
        <a class="dropdown-item" role="button" (click)="logout()">
            <div class="dropdown-item-icon"><i-feather name="log-out"></i-feather></div>
            Cerrar sesión
        </a>
    </div>
</li>
