/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDownLong,
    faArrowLeft,
    faArrowTrendDown,
    faArrowTrendUp,
    faBars,
    faBookOpen,
    faBoxArchive,
    faBoxes,
    faCalendar,
    faCartPlus,
    faCartShopping,
    faChartArea,
    faChartBar,
    faChartColumn,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleMinus,
    faCircleXmark,
    faClipboardList,
    faColumns,
    faCommentAlt,
    faDollarSign,
    faDownload,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandAlt,
    faExpandArrowsAlt,
    faFileLines,
    faFlag,
    faLongArrowAltRight,
    faMinus,
    faMoneyBill,
    faMoneyBill1,
    faMoneyCheck,
    faMoneyCheckDollar,
    faMousePointer,
    faPercentage,
    faPerson,
    faPlus,
    faPlusCircle,
    faPrint,
    faReceipt,
    faRemove,
    faSave,
    faSearch,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faToolbox,
    faTrash,
    faUpload,
    faUser,
    faUserCircle,
    faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faArrowLeft,
    faBars,
    faBookOpen,
    faBoxes,
    faToolbox,
    faCartPlus,
    faMoneyCheckDollar,
    faChartArea,
    faChartBar,
    faChartPie,
    faChartColumn,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleXmark,
    faClipboardList,
    faColumns,
    faDollarSign,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandAlt,
    faExpandArrowsAlt,
    faFileLines,
    faFlag,
    faLongArrowAltRight,
    faCircleMinus,
    faMoneyBill,
    faMoneyCheck,
    faMoneyBill1,
    faMousePointer,
    faPercentage,
    faPerson,
    faPlus,
    faPlusCircle,
    faReceipt,
    faRemove,
    faSearch,
    faSave,
    faTable,
    faTachometerAlt,
    faTag,
    faTimes,
    faTrash,
    faUser,
    faUserPlus,
    faUserCircle,
    faArrowTrendDown,
    faArrowTrendUp,
    faCommentAlt,
    faArrowDownLong,
    faDownload,
    faUpload,
    faCartShopping,
    faMinus,
    faBoxArchive,
    faPrint,
    faCalendar,
};
