import { Identify, identify, setGroup, track } from '@amplitude/analytics-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import posthog from 'posthog-js';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private experiment: ExperimentClient;

    constructor() {
        this.initializeAnalytics();
        this.initializeExperiment();
    }

    private initializeAnalytics() {
        const sessionReplayTracking = sessionReplayPlugin();

        amplitude.add(sessionReplayTracking);
        amplitude.init(environment.AMPLITUDE_KEY, { autocapture: true });

        if (environment.production) {
            console.log('opting in');
            posthog.init(environment.POSTHOG_KEY, {
                api_host: environment.POSTHOG_HOST,
            });
        }
    }

    analyticsCapture(eventName: string, eventProperties: object = {}) {
        // POSTHOG
        posthog.capture(eventName, eventProperties);

        // AMPLITUDE
        track(eventName, eventProperties);
    }

    analyticsIdentify(userId: string, tenantId: string, userProperties: object = {}) {
        // POSTHOG
        posthog.identify(userId, userProperties);

        // AMPLITUDE
        console.log('analyticsIdentify', userId, tenantId, userProperties);
        const identifyObj = new Identify();

        // Set each user property on the identifyObj
        Object.entries(userProperties).forEach(([key, value]) => {
            identifyObj.set(key, value);
        });

        identify(identifyObj, { user_id: userId });
        setGroup('tenant', tenantId, { user_id: userId });
    }

    startSessionRecording() {
        posthog.startSessionRecording();
    }

    async initializeExperiment() {
        this.experiment = Experiment.initializeWithAmplitudeAnalytics(environment.AMPLITUDE_KEY);
        await this.experiment.fetch();
    }

    getExperimentVariant(flagKey: string): boolean {
        if (!this.experiment) {
            console.warn('Experiment not initialized. Call initializeExperiment() first.');
            return false;
        }
        return this.experiment.variant(flagKey).value === 'on';
    }
}
