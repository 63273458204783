<footer class="footer-admin mt-auto" [ngClass]="{ 'footer-light': !dark, 'footer-dark': dark }">
    <div class="container-fluid px-4">
        <div class="row gx-4">
            <div class="col-md-6 small">Copyright &copy; FINA PARTNER 2022</div>
            <div class="col-md-6 text-md-end small">
                <a [routerLink]="['/legal/terms-conditions']">Términos y Condiciones</a>
                &middot;
                <a [routerLink]="['/legal/privacy-policy']">Políticas de Privacidad</a>
            </div>
        </div>
    </div>
</footer>
