import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'sb-layout-auth',
    templateUrl: './layout-auth.component.html',
    styleUrls: ['layout-auth.component.scss'],
})
export class LayoutAuthComponent {
    constructor() {}
}
