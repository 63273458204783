import { Component, HostListener, OnInit } from '@angular/core';
import { AnalyticsService, PlansService } from '@common/services';
import { UserService } from '@modules/auth/services';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    public isSmallScreen = false;

    constructor(private navigationService: NavigationService, public analyticsService: AnalyticsService, public userService: UserService, public plansService: PlansService) {}

    ngOnInit() {
        this.checkScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkScreenSize();
    }

    private checkScreenSize() {
        this.isSmallScreen = window.innerWidth < 576; // Bootstrap's sm breakpoint
    }

    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }
}
