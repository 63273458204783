import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'sb-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Input() dark = false;
    constructor() {}
}
