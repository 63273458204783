import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { DiscountInterface } from '../models/discount';

@Injectable()
export class DiscountService {
    _discounst$: BehaviorSubject<DiscountInterface[]> = new BehaviorSubject<DiscountInterface[]>([]);

    API_URL = environment.API_URL;

    constructor(private http: HttpClient) {}

    get discounts$() {
        return this._discounst$;
    }

    set discounts(discounts: DiscountInterface[]) {
        this._discounst$.next(discounts);
    }

    getDiscounts$() {
        return this.http.get<any>(`${this.API_URL}/discount`);
    }

    getDiscountByCode$(code: string) {
        return this.http.get<any>(`${this.API_URL}/discount/${code}`);
    }

    createDiscount$(discounts: DiscountInterface) {
        return this.http.post<any>(`${this.API_URL}/discount`, { ...discounts });
    }

    updateDiscount$(discountId: string, discounts: DiscountInterface) {
        return this.http.put<any>(`${this.API_URL}/discount/${discountId}`, { ...discounts });
    }

    deleteDiscount$(discountId: string) {
        return this.http.delete<any>(`${this.API_URL}/discount/${discountId}`);
    }
}
