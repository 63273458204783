import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fina-confirm-deletion',
    templateUrl: './confirm-deletion.component.html',
    styleUrls: ['confirm-deletion.component.scss'],
})
export class ConfirmDeletionComponent {
    @Input() title: string;
    @Input() message: string;
    @Input() params: any;
    @Input() icon: string[];
    @Input() styles: Object;
    @Output() deletionEvent = new EventEmitter<any>();

    constructor(private modalService: NgbModal) {}

    onConfirm(values: any): void {
        this.deletionEvent.emit(values);
        (async () => {
            await this.delay(240);
            this.modalService.dismissAll(true);
        })();
    }

    open(content: TemplateRef<any>, modalOptions: NgbModalOptions = {}) {
        this.modalService.open(content, modalOptions).result.then(
            result => {
                console.log(`Closed with: ${result}`);
            },
            reason => {
                console.log(`Dismissed ${this._getDismissReason(reason)}`);
            },
        );
    }

    _getDismissReason(reason: unknown): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    delay(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
}
