/* tslint:disable: ordered-imports*/
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@modules/icons/icons.module';
/* Modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const modules = [IconsModule, NgbModule];

/* Components */
import * as appCommonComponents from './components';
import * as appCommonContainers from './containers';
/* Directives */
import * as appCommonDirectives from './directives';
/* Guards */
import * as appCommonGuards from './guards';

@NgModule({
    imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, ...modules],
    declarations: [...appCommonContainers.containers, ...appCommonComponents.components, ...appCommonDirectives.directives],
    exports: [...appCommonContainers.containers, ...appCommonComponents.components, ...appCommonDirectives.directives, ...modules],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                // ...appCommonServices.services, NO PROVEER LOS SERVICIOS COMMON AQUI, DEBEN TENER EL FORROOT PARA SER GLOBALES
                ...appCommonGuards.guards,
                ...appCommonDirectives.directives,
                { provide: 'window', useValue: window },
            ],
        };
    }
}
