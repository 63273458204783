<div class="card border-top-0 border-bottom-0 border-end-0 border-gradient-purple">
    <div class="card-header border-bottom-0 d-flex align-items-center text-danger">
        <div class="fw-bolder h4 pt-1 mb-0">Novedades más recientes</div>
    </div>

    <div class="card-body">
        <div
            class="news-list m-2"
            [ngClass]="
                (newsService.loader$ | async) || (newsService.listNews$ | async)?.length === 0
                    ? 'd-flex align-items-center justify-content-center'
                    : ''
            "
        >
            <div *ngIf="newsService.loader$ | async; then spinner; else data"></div>
        </div>
    </div>
</div>

<ng-template #data>
    <div *ngIf="(newsService.listNews$ | async)?.length > 0; then state; else emptyState"></div>
</ng-template>

<ng-template #state>
    <ul>
        <li *ngFor="let obj of newsService.listNews$ | async">
            <a (click)="newsService.showModal(obj)" [title]="'Abrir modal para: ' + obj?.title">{{ obj?.title }}</a>
        </li>
    </ul>
</ng-template>

<ng-template #emptyState><fina-text-empty-state text="No hay novedades"></fina-text-empty-state></ng-template>
<ng-template #spinner><fina-spinner></fina-spinner></ng-template>
