<div
    class="toast show mb-1 border-0 shadow-none toast-box"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-delay="5000"
    [style]="{ 'background-color': '' + color + '' }"
>
    <div class="toast-content d-flex" [style]="{ 'background-color': '' + bg + '' }">
        <div class="d-flex align-items-center p-0">
            <svg
                *ngIf="icon === 'circle-info'"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="#0d6efd"
                class="bi bi-info-circle-fill"
                viewBox="0 0 16 16"
            >
                <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
            </svg>

            <fa-icon
                *ngIf="icon !== 'circle-info'"
                [icon]="['fas', '' + icon + '']"
                [styles]="{ color: '' + color + '', 'font-size': '35' }"
            ></fa-icon
            >&nbsp;
        </div>
        <div class="p-3 px-2 flex-grow-1">
            <ng-container
                ><b class="text-gray-800">{{ header }}</b></ng-container
            >
            <br />
            <ng-container>{{ body }}</ng-container>
        </div>
        <button
            type="button"
            class="btn-close p-3 mr-0 close"
            data-bs-dismiss="toast"
            aria-label="Close"
            (click)="toastService.remove(toastID)"
        ></button>
    </div>
</div>
