// Estos servicios no se exportan en una variable porque no deben ser incluidos en los providers de ningun modulo
// esto es para que el ProvidedIn: root, funcione correctamente y los servicios sean singleton.
// se mantienen los exports individuales que son para que los componentes puedan utilizar los servicios.

export * from './analytics.service';
export * from './auth.service';
export * from './bank-accounts.service';
export * from './commerce.service';
export * from './date-range.service';
export * from './extraServices.service';
export * from './fixed-variable-expenses.service';
export * from './instructions-modal.service';
export * from './loading.service';
export * from './news.service';
export * from './order.service';
export * from './pagination.service';
export * from './plans.service';
export * from './sales-channels.service';
export * from './staff.service';
export * from './toast.service';
export * from './utility.service';
export * from './sales-channels.service';
