// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    API_URL: 'https://dev-finaapi.onrender.com/api',
    // API_URL: 'http://test.fina.test:4000/api',

    POSTHOG_KEY: 'no-key',
    POSTHOG_HOST: 'no-host',

    AMPLITUDE_KEY: '9dc0677f5991ad3af6cb66c813595575',

    INTERCOM_APP_ID: 't2hw46qi',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
