import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// Importa el locale español
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AppCommonModule } from '@common/app-common.module';
import { AuthInterceptorService } from '@modules/auth/services';
import { NavigationModule } from '@modules/navigation/navigation.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Registra el locale español
registerLocaleData(localeEs);

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule, NgxSliderModule, AppCommonModule.forRoot(), NavigationModule.forRoot()],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: 'es-ES' }, // Añade esta línea para configurar el locale a español
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
