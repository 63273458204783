import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    // Current version of app stored in client
    private currentVersion: string = '';

    constructor(private http: HttpClient) {}

    /**
     * Checks version every 5 minutes
     */
    public initVersionCheck(versionUrl: string, frequency = 1000 * 60 * 5) {
        return interval(frequency).pipe(switchMap(() => this.checkVersion(versionUrl)));
    }

    /**
     * Checks if version has changed
     */
    private checkVersion(versionUrl: string): Observable<boolean> {
        return this.http.get<{ version: string }>(versionUrl).pipe(
            map(response => {
                const newVersion = response.version;
                if (!this.currentVersion) {
                    this.currentVersion = newVersion;
                    return false;
                }

                if (this.currentVersion !== newVersion) {
                    return true; // Version is different
                }
                return false;
            }),
        );
    }
}
