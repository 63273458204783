import { Injectable } from '@angular/core';
import { InstructionsModalComponent } from '@common/containers/instructions-modal/instructions-modal.component';
import { EmbeddedVideoOptions } from '@common/interfaces';
import { UserService } from '@modules/auth/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { AnalyticsService } from './analytics.service';
import { InstructionsService } from './instructions.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class InstructionsModalService {
    modalRef: any;
    instructionsRequired: string[] = [
        'direct-sales-es-inventory',
        'direct-sales-es-sales-channels',
        'sales-channels-es-inventory',
        'product-template-es-inventory',
    ];
    loginQuantity: number;

    constructor(
        private modalService: NgbModal,
        private instructionsService: InstructionsService,
        private userService: UserService,
        private toastService: ToastService,
        private analyticsService: AnalyticsService,
    ) {}

    async showModal(instructionsSetName: string, videoOptions?: EmbeddedVideoOptions, bypassNotShow: boolean = false) {
        try {
            // const loginResponse = await firstValueFrom(this.userService.getLoginQuantity$());
            // this.loginQuantity = loginResponse.data.loginAmount;

            // if(this.loginQuantity === 1) return;

            const getInstructionsSubscription = this.instructionsService.getInstructionsSetByName(instructionsSetName).subscribe({
                next: response => {
                    if (
                        !bypassNotShow &&
                        this.userService.user$.getValue()?.instructionsNotToShow?.includes(instructionsSetName) &&
                        !this.instructionsRequired.includes(instructionsSetName)
                    ) {
                        getInstructionsSubscription.unsubscribe();
                        return;
                    }

                    const { title, videoUrl, steps, text, lock } = response.data;

                    let block = {};
                    if (lock) block = { backdrop: 'static', keyboard: false };
                    this.analyticsService.analyticsCapture('onboarding_mostrar modal', { modulo: title });
                    this.analyticsService.startSessionRecording();
                    this.modalRef = this.modalService.open(InstructionsModalComponent, {
                        centered: true,
                        size: lock ? 'md' : 'xl',
                        ...block,
                        beforeDismiss: () => false,
                    });

                    const { autoplay, startTime, muted } = videoOptions || {};
                    this.modalRef.componentInstance.videoUrl = `${videoUrl}?${this.getEmbedOptionsParams({ autoplay, startTime, muted })}`;
                    this.modalRef.componentInstance.instructions = [...steps];
                    this.modalRef.componentInstance.text = text;
                    this.modalRef.componentInstance.lock = lock;
                    this.modalRef.componentInstance.modalTitle = title;
                    this.modalRef.componentInstance.modalIconTitle = '';
                    this.modalRef.componentInstance.instructionsSetName = instructionsSetName;

                    this.modalRef.result.then(({ doNotShowAgain }: any) => {
                        if (doNotShowAgain) {
                            this.instructionsService.doNotShowAgain(instructionsSetName).subscribe({
                                next: () => {
                                    const user = this.userService.user$.getValue();
                                    user.instructionsNotToShow = [...user.instructionsNotToShow, instructionsSetName];
                                    this.userService.user = user;
                                    this.toastService.success('¡Hecho!', 'Esta ayuda no se mostrará de nuevo.');
                                },
                                error: (err: any) => {
                                    console.error(err);
                                    this.toastService.error('¡Ups!', 'Por los momentos no se ha podido guardar tu preferencia.');
                                },
                            });
                        }
                    });
                },

                error: (err: any) => {
                    console.error(err);
                    if (this.modalRef) {
                        this.modalRef.close();
                    }
                    this.modalRef = null;
                },
            });
        } catch (err: any) {
            if (err?.error?.title && err?.error?.message) {
                this.toastService.error(err.error.title, err.error.message);
            } else {
                console.error(err);
            }
        }
    }

    private getEmbedOptionsParams({ hideEmbedTopBar, startTime, muted, autoplay, hide_share, hide_title, hide_owner }: any = {}) {
        const embedOptions = {
            hideEmbedTopBar: typeof hideEmbedTopBar === 'boolean' ? hideEmbedTopBar : true,
            t: typeof startTime === 'string' ? startTime : '0s',
            muted: typeof muted === 'boolean' ? muted : false,
            autoplay: typeof autoplay === 'boolean' ? autoplay : false,
            hide_share: typeof hide_share === 'boolean' ? hide_share : true,
            hide_title: typeof hide_title === 'boolean' ? hide_title : true,
            hide_owner: typeof hide_owner === 'boolean' ? hide_owner : true,
        };

        let embedVideoParams = new URLSearchParams();
        for (const [key, value] of Object.entries(embedOptions)) {
            embedVideoParams.append(key, value.toString());
        }

        return embedVideoParams.toString();
    }
}
