import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'sbpro-top-nav-search',
    templateUrl: './top-nav-search.component.html',
    styleUrls: ['top-nav-search.component.scss'],
})
export class TopNavSearchComponent {
    constructor() {}
}
