import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstructionsSet, InstructionsSetResponse } from '@common/interfaces';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InstructionsService {
    API_URL = environment.API_URL;
    instructionsSet$ = new BehaviorSubject<InstructionsSet>({
        title: '',
        name: '',
        videoUrl: '',
        steps: [],
        text: '',
    });
    constructor(private http: HttpClient) {}

    getInstructionsSetByName(instructionsSetName: string) {
        return this.http.get<InstructionsSetResponse>(`${this.API_URL}/instructions/${instructionsSetName}`);
    }

    doNotShowAgain(instructionsSetName: string) {
        return this.http.get<any>(`${this.API_URL}/instructions/do-not-show-again/${instructionsSetName}`);
    }
}
