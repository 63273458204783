import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { Commerce } from '../../commerce/models';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class CommerceService {
    API_URL = environment.API_URL;

    _commerce$: BehaviorSubject<Commerce> = new BehaviorSubject<Commerce>(null);
    exchangeRateBCV$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    exchangeRateInUse$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private http: HttpClient, private toastService: ToastService) {}

    updateCommerce$(commerce: Commerce): Observable<Commerce> {
        return this.http.put<any>(`${this.API_URL}/tenants/${commerce._id}`, commerce);
    }

    updateCommerceImg$(file: FormData): Observable<Commerce> {
        return this.http.post<any>(`${this.API_URL}/cloud/upload-file`, file);
    }

    resetCommerce$(commerce: Commerce): Observable<Commerce> {
        return this.http.put<any>(`${this.API_URL}/reverse-operations/restart-tenant/${commerce.tenantId}`, {});
    }

    resetInventory$(resetInZero: boolean): Observable<Commerce> {
        return this.http.put<any>(`${this.API_URL}/reverse-operations/restart-inventory`, { resetInZero });
    }

    get commerce(): Commerce {
        if (this._commerce$.value) {
            return this._commerce$.value;
        } else {
            this.obtainCommerce().then(res => {
                console.log('retornando commerce');
                return res;
            });
        }
    }

    set commerce(commerce: Commerce) {
        this._commerce$.next(commerce);
        this.setExchangeRateInUse();
    }

    async obtainCommerce(): Promise<any> {
        this.http.get<any>(`${this.API_URL}/tenants/tenantId`).subscribe({
            next: (res: any) => {
                this.commerce = res.data;
                return res.data;
            },
            error: (err: any) => {
                console.error(err);
                return null;
            },
        });
    }

    get exchangeRateBCV(): number {
        if (this.exchangeRateBCV$.value) {
            return this.exchangeRateBCV$.value;
        } else {
            this.obtainExchangeRateBCV$().then(res => {
                console.log('retornando bcv');
                return res;
            });
        }
    }

    async obtainExchangeRateBCV$(): Promise<any> {
        this.http.get(`${this.API_URL}/tenants/bcv/`).subscribe({
            next: (res: any) => {
                this.exchangeRateBCV$.next(res.data);
                this.setExchangeRateInUse();
                return res.data;
            },
            error: () => {
                this.exchangeRateBCV$.next(null);
                return null;
            },
        });
    }

    setExchangeRateInUse() {
        if (!this._commerce$.value || !this.exchangeRateBCV$.value) return;

        if (this._commerce$.value.usePreferentialRate) {
            this.exchangeRateInUse$.next(this._commerce$.value.preferentialRate);
        } else {
            this.exchangeRateInUse$.next(this.exchangeRateBCV);
        }
    }

    getEmptyState$(): Observable<any> {
        return this.http.get(`${this.API_URL}/tenants/emptyState/`);
    }
}
