import { Logger } from './logger.class';

export type Actions<TAction extends string> = { [K in TAction]: (payload?: { [k: string]: any }) => void };

export type InlineActions = (payload?: { [k: string]: any }) => void;

export type MachineTransitionObject<TState, TAction> = {
    target: TState;
    actions: TAction[] | InlineActions;
};

export type Machine<TState extends string, TEvent extends string, TAction> = {
    initial: TState;
    states: {
        [K in TState]: {
            on: {
                [E in TEvent]?: TState | MachineTransitionObject<TState, TAction>;
            };
            actions?: {
                entry?: TAction[];
                exit?: TAction[];
            };
        };
    };
};

export class LittleStateMachine<
    TMachine extends Machine<TState, TEvent, TAction>,
    TMachineActions extends Actions<TAction>,
    TState extends string,
    TEvent extends string,
    TAction extends string,
> {
    private machine: TMachine;
    private actions: TMachineActions;
    private machineCurrentState: TState;
    private processingTransition: boolean = false;
    private logger: Logger;
    constructor(machine: TMachine, actions: TMachineActions, showLogs: boolean = false) {
        this.machine = machine;
        this.actions = actions;
        this.machineCurrentState = machine.initial;
        this.logger = new Logger(showLogs);

        const entryActions = this.machine.states[this.machineCurrentState]?.actions?.entry;
        if (entryActions) {
            entryActions.forEach((action: TAction) => {
                this.actions[action]?.();
            });
        }
    }

    send(event: TEvent, payload?: {}) {
        this.logger.group(`[${this.machineCurrentState}] = sent => ${event} | ${payload}`);
        if (this.processingTransition) {
            this.logger.info('processing transition');
            return;
        }
        this.processingTransition = true;
        const currentState = this.machineCurrentState;

        const validTransition: TState | MachineTransitionObject<TState, TAction> = this.machine.states[currentState].on[event] as
            | TState
            | MachineTransitionObject<TState, TAction>;

        if (!validTransition) {
            this.processingTransition = false;
            this.logger.warn('invalid transition');
            this.logger.groupEnd();
            return;
        }

        const transitionObject: TState | MachineTransitionObject<TState, TAction> = this.machine.states[currentState].on[event] as
            | TState
            | MachineTransitionObject<TState, TAction>;
        const transitionActions =
            typeof transitionObject === 'object' && 'actions' in transitionObject ? (transitionObject.actions as TAction[]) : null;

        this.logger.info('transition actions', transitionActions);
        if (transitionActions) {
            if (typeof transitionActions === 'function') {
                const inlineActions = transitionActions as InlineActions;
                inlineActions(payload);
            } else {
                transitionActions.forEach((action: TAction) => {
                    this.actions[action]?.(payload);
                });
            }
        }

        const exitActions = this.machine.states[currentState]?.actions?.exit;

        this.logger.info('exit actions', exitActions);
        if (exitActions) {
            exitActions.forEach((action: TAction) => {
                this.actions[action]?.(payload);
            });
        }

        let nextState: TState | MachineTransitionObject<TState, TAction> = this.machine.states[currentState].on[event] as
            | TState
            | MachineTransitionObject<TState, TAction>;
        nextState = typeof nextState === 'object' && 'target' in nextState ? (nextState.target as TState) : (nextState as TState);
        this.logger.info('after event state: ', nextState);

        const entryActions = this.machine.states[nextState]?.actions?.entry;
        this.logger.info('entry actions', entryActions);

        this.machineCurrentState = nextState;
        this.logger.info(this.machineCurrentState);
        this.processingTransition = false;
        if (entryActions) {
            entryActions.forEach((action: TAction) => {
                this.logger.info('payload', payload);
                this.actions[action]?.(payload);
            });
        }
        this.logger.groupEnd();
    }

    get state() {
        return this.machineCurrentState;
    }
}
