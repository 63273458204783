import { Component, Input } from '@angular/core';
import { AuthService } from '@common/services';
import { UserService } from '@modules/auth/services';
@Component({
    selector: 'sbpro-top-nav-user',
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent {
    @Input() placement = 'bottom-end';
    dropdownClasses: string[] = [];

    constructor(public userService: UserService, public authService: AuthService) {}

    logout() {
        this.authService.logout();
    }
}
