import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

import { AllPermissions, PlansBundle, PlansPermissions, PlansResponse } from '../models/plans.model';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class PlansService {
    public plansBundle$: BehaviorSubject<PlansBundle> = new BehaviorSubject<PlansBundle>(null);
    public planInvalid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public titlePlanInvalid$: BehaviorSubject<string> = new BehaviorSubject<string>('Este modulo no pertenece a tu plan');
    public messagePlanInvalid$: BehaviorSubject<string> = new BehaviorSubject<string>('Para poder disfrutar de este modulo debes de mejorar tu plan.');

    API_URL = environment.API_URL;

    constructor(private http: HttpClient, private toastService: ToastService) {}

    getPaymentMethods$() {
        return this.http.get<any>(`${this.API_URL}/plans/payment-methods`);
    }

    payPlan$(data: any) {
        return this.http.post<any>(`${this.API_URL}/plans/pay`, data);
    }

    async updatePlan$(planId: string, paymentFrequency: string) {
        return this.http.put<any>(`${this.API_URL}/plans/${planId}`, { paymentFrequency }).subscribe({
            next: res => {
                this.plansBundle$.next(res.data);
                return res;
            },
            error: err => {
                this.toastService.error(err.error.title, err.error.message);
                return null;
            },
        });
    }

    async obtainPlansBundle(): Promise<any> {
        this.http.get<PlansResponse>(`${this.API_URL}/plans`).subscribe({
            next: res => {
                this.plansBundle$.next(res.data);
                return res.data;
            },
            error: err => {
                console.log('Error obtaining plans.', err);
                this.toastService.error(err.error.title, err.error.message);
                return null;
            },
        });
    }

    // obtainCurrentPlan() {
    //     console.log('Obtaining current plan')
    //     this.http.get<any>(`${this.API_URL}/plans/validate-plan-permissions`).subscribe({
    //         next: (res) => {
    //             this.currentPlan$.next(res.data);
    //         },
    //         error: (err) => {
    //             console.log('Error obtaining the current plan.', err)
    //             this.toastService.error(err.error.title, err.error.message);
    //         }
    //     })
    // }

    async hasPlanPermission(module: keyof PlansPermissions, permission: AllPermissions): Promise<any> {
        return this.plansBundle$.subscribe({
            next: (plansBundle: PlansBundle) => {
                if (plansBundle) {
                    if (plansBundle.currentPlan.permissions[module].includes(permission)) {
                        this.planInvalid$.next(false);
                        return false;
                    } else {
                        this.planInvalid$.next(true);
                        return true;
                    }
                }
            },
        });
    }

    delay(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
}
