import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    API_URL = environment.API_URL;
    hideModal: boolean = false;
    hideMessage: boolean = false;
    loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _dataNews$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _listNews$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(private http: HttpClient, private toastService: ToastService) {}

    get dataNews$(): Observable<any> {
        return this._dataNews$.asObservable();
    }

    set dataNews(data: any) {
        this._dataNews$.next({ ...data });
    }

    get listNews$(): Observable<any> {
        return this._listNews$.asObservable();
    }

    set listNews(data: any[]) {
        this._listNews$.next([...data]);
    }

    loadNews() {
        this.getLatestNews$().subscribe({
            next: (res: any) => {
                if (res?.data) this.dataNews = { ...res.data };
            },
        });
    }

    loadRecentNews() {
        this.loader$.next(true);
        this.getRecentNews$().subscribe({
            next: (res: any) => {
                if (res?.data?.length > 0) this.listNews = [...res.data];
                this.loader$.next(false);
            },
            error: () => {
                this.loader$.next(false);
            },
        });
    }

    showModal(newObject: any) {
        this.hideMessage = true;
        this.dataNews = { ...newObject };
    }

    onHideModal() {
        if (this.hideModal) {
            this.hideLatestNews$().subscribe({
                next: () => {
                    this.hideModal = false;
                },
                error: err => {
                    this.toastService.error(err.error.title, err.error.message);
                },
            });
        }
        setTimeout(() => {
            this.hideMessage = false;
        }, 200);
    }

    private getLatestNews$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/news/latest-news`);
    }

    private getRecentNews$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/news/recent-news`);
    }

    private hideLatestNews$(): Observable<any> {
        return this.http.post<any>(`${this.API_URL}/news/hide-latest-news`, {});
    }
}
