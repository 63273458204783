/* tslint:disable: ordered-imports*/
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import * as CommerceService from '@modules/commerce/services';

/* Components */
import * as navigationComponents from './components';
/* Containers */
import * as navigationContainers from './containers';
/* Guards */
import * as navigationGuards from './guards';
/* Layouts */
import * as appCommonLayouts from './layouts';
/* Services */
import * as navigationServices from './services';

@NgModule({
    imports: [CommonModule, RouterModule, AppCommonModule],
    declarations: [...navigationContainers.containers, ...navigationComponents.components, ...appCommonLayouts.layouts],
    exports: [...navigationContainers.containers, ...navigationComponents.components, ...appCommonLayouts.layouts],
    providers: [{ provide: 'window', useValue: window }],
})
export class NavigationModule {
    static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            providers: [...navigationServices.services, ...CommerceService.services, ...navigationGuards.guards],
        };
    }
}
