<div class="modal-header">
    <div class="title-container">
        <div *ngIf="modalIconTitle" class="icon-wrapper"></div>
        <h1 class="title">{{ modalTitle || 'Instrucciones' | titlecase }}</h1>
    </div>
    <button
        class="btn-close-modal rounded-circle"
        *ngIf="!lock"
        (click)="
            activeModal.close({ doNotShowAgain: doNotShowAgain });
            analyticsService.analyticsCapture('onboarding_cerrar modal', { noMostrarMas: doNotShowAgain, modulo: modalTitle })
        "
    >
        <img src="assets/icons/svg/icon-btn-close-modal.svg" alt="Cerrar modal" />
    </button>
</div>
<div class="modal-body px-4">
    <div class="content row">
        <div class="col-12 col-lg-8 align-content-center mt-3 mb-3" *ngIf="!lock">
            <div *ngIf="isLoading; then spinner"></div>
            <div class="video-container" [hidden]="isLoading">
                <iframe
                    [src]="safeVideoUrl"
                    (load)="onIframeLoad()"
                    [title]="'Instrucciones: ' + modalTitle"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                >
                </iframe>
                <div *ngIf="showOverlay" class="overlay d-flex align-items-center justify-content-center" (click)="onClickIframe()">
                    <h2 class="text-white h2 video_action fw-bold">Ver video</h2>
                </div>
            </div>
        </div>
        <div class="box-text mt-3 mb-3" [ngClass]="lock ? 'col-12' : 'col-12 col-lg-4'" [innerHTML]="safeText"></div>
    </div>
</div>
<div class="modal-footer" *ngIf="!lock">
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            id="doNotShowAgain"
            name="doNotShowAgain"
            [checked]="doNotShowAgain"
            [(ngModel)]="doNotShowAgain"
        />
        <label class="check-input-label" for="doNotShowAgain">No volver a mostrar</label>
    </div>
    <button
        class="btn btn-primary close-btn"
        (click)="
            activeModal.close({ doNotShowAgain: doNotShowAgain });
            analyticsService.analyticsCapture('onboarding_cerrar modal', { noMostrarMas: doNotShowAgain, modulo: modalTitle })
        "
    >
        Cerrar
    </button>
</div>

<ng-template #spinner>
    <fina-spinner></fina-spinner>
</ng-template>
