import { Injectable } from '@angular/core';
import { Sale } from '@modules/pos/models';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    removeConfirmationMessage: boolean = false;

    public reformatProductsToShow(sale: Sale): any[] {
        let products: any[] = [];
        if (sale?.productVariations?.length > 0) {
            products = [...this.reduceProductVariations(sale.productVariations)];
            products = [...this.setNameOfVariations(products)];
        } else {
            products = [...this.reduceProducts(sale.products)];
        }

        return [...products];
    }

    private reduceProductVariations(pvs: any[]): any[] {
        return pvs.reduce((pv: any[], cv: any) => {
            // pv: previous value; cv: current value

            const variations: any[] = this.getVariations(cv.variations);
            const location: string = this.getLocation(cv.location);

            let idx: number = -1;

            const match = pv.filter((obj: any, i: number) => {
                const value: boolean = this.compareVariationAndLocation(obj, { product: cv.product, variations, location });
                if (value) idx = i;
                return value;
            })[0];

            if (match && idx >= 0) {
                return [...this.getNewPreviousValue(pv, cv, idx)];
            } else {
                return [...pv, { ...cv, variations, location, name: cv.product.name, amount: cv.amount || 1 }];
            }
        }, []);
    }

    private reduceProducts(products: any[]) {
        return products.reduce((pv: any[], cv: any) => {
            let idx: number = -1;

            const match = pv.filter((p: any, i: number) => {
                const value: boolean = p.product._id === cv._id;
                if (value) idx = i;
                return value;
            })[0];

            if (match && idx >= 0) {
                return [...this.getNewPreviousValue(pv, cv, idx)];
            } else {
                return [...pv, { product: { ...this.getProductWithUnits(cv) }, variations: [], location: 'cualquiera', amount: 1 }];
            }
        }, []);
    }

    private getVariations(variations: any[]): any[] {
        return variations.map(v => {
            return {
                itemId: v.itemId,
                variation: v.variation !== null && v.variation !== undefined && v.variation !== '' ? String(v.variation).trim().toLowerCase() : null,
            };
        });
    }

    private getLocation(location: string): string {
        return location !== null && location !== undefined && location !== '' ? String(location).trim().toLowerCase() : 'cualquiera';
    }

    private compareVariationAndLocation(pv: any, cv: any): boolean {
        return pv.product._id === cv.product._id && JSON.stringify(pv.variations) === JSON.stringify(cv.variations) && pv.location === cv.location;
    }

    private getNewPreviousValue(pv: any[], cv: any, i: number): any[] {
        let arrayAux: any[] = [...pv];
        arrayAux[i] = {
            ...arrayAux[i],
            amount: arrayAux[i].amount + (cv.amount || 1),
        };
        return [...arrayAux];
    }

    private setNameOfVariations(products: any[]) {
        return products.map((p: any) => {
            let variations: any[] = [...p.variations];
            return {
                ...p,
                product: { ...this.getProductWithUnits(p?.product) },
                variations: [...this.getVariationsWithName(variations, p)],
            };
        });
    }

    private getVariationsWithName(variations: any[], p: any) {
        return [
            ...variations.map(v => {
                let itemName: string = p.product.items.filter((item: any) => item.itemId === v.itemId)[0]?.itemName;
                return {
                    ...v,
                    itemName,
                };
            }),
        ];
    }

    private getProductWithUnits(p: any) {
        return {
            ...p,
            units: p.referenceType === 'template' ? 'Und.' : p?.items?.[0]?.item?.units || 'Und.',
        };
    }
}
