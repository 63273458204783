<li class="nav-item dropdown no-caret d-none d-md-block me-3" ngbDropdown [placement]="placement" display="dynamic">
    <a
        class="nav-link dropdown-toggle pointer"
        id="navbarDropdownDocs"
        ngbDropdownToggle
        data-cy="docsMenu"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        ><div class="fw-500">Documentation</div>
        <fa-icon class="dropdown-arrow" [icon]="['fas', 'chevron-right']"></fa-icon
    ></a>
    <div class="dropdown-menu dropdown-menu-end py-0 o-hidden animated--fade-in-up" ngbDropdownMenu aria-labelledby="navbarDropdownDocs">
        <a class="dropdown-item py-3" href="https://docs.startbootstrap.com/sb-admin-pro-angular" target="_blank"
            ><div class="icon-stack bg-primary-soft text-primary me-4"><i-feather name="book"></i-feather></div>
            <div>
                <div class="small text-gray-500">Documentation</div>
                Usage instructions and reference
            </div></a
        >
        <div class="dropdown-divider m-0"></div>
        <a class="dropdown-item py-3" href="https://docs.startbootstrap.com/sb-admin-pro-angular/development-create" target="_blank"
            ><div class="icon-stack bg-primary-soft text-primary me-4"><i-feather name="code"></i-feather></div>
            <div>
                <div class="small text-gray-500">Tutorial</div>
                Create a Module
            </div></a
        >
        <div class="dropdown-divider m-0"></div>
        <a class="dropdown-item py-3" href="https://docs.startbootstrap.com/sb-admin-pro-angular/changelog" target="_blank"
            ><div class="icon-stack bg-primary-soft text-primary me-4"><i-feather name="file-text"></i-feather></div>
            <div>
                <div class="small text-gray-500">Changelog</div>
                Updates and changes
            </div></a
        >
    </div>
</li>
