type LogLevel = 'log' | 'warn' | 'error' | 'info' | 'debug' | 'group';
export class Logger {
    private isLoggingEnabled: boolean;

    constructor(isLoggingEnabled: boolean = true) {
        this.isLoggingEnabled = isLoggingEnabled;
    }

    private logMessage(level: LogLevel, ...args: any[]): void {
        if (this.isLoggingEnabled && console[level]) {
            console[level](...args);
        }
    }

    info(...args: any[]): void {
        this.logMessage('info', ...args);
    }

    warn(...args: any[]): void {
        this.logMessage('warn', ...args);
    }

    error(...args: any[]): void {
        this.logMessage('error', ...args);
    }

    debug(...args: any[]): void {
        this.logMessage('debug', ...args);
    }

    log(...args: any[]): void {
        this.logMessage('log', ...args);
    }

    group(...args: any[]): void {
        this.logMessage('group', ...args);
    }

    groupEnd(): void {
        console.groupEnd();
    }
    enableLogging(): void {
        this.isLoggingEnabled = true;
    }

    disableLogging(): void {
        this.isLoggingEnabled = false;
    }
}
