import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loadingCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public get loading(): boolean {
        return this.loading$.getValue();
    }

    isLoading(makeLoading: boolean): void {
        if (makeLoading) {
            this.loadingCount$.next(this.loadingCount$.getValue() + 1);
        } else {
            const count = this.loadingCount$.getValue() <= 1 ? 0 : this.loadingCount$.getValue() - 1;
            this.loadingCount$.next(count);
        }
        this.loading$.next(this.loadingCount$.getValue() > 0);
    }
}
