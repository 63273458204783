<span [ngClass]="{ 'nav-fixed': !static }">
    <sb-top-nav></sb-top-nav>
    <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
            <sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems$ | async" [sideNavSections]="sideNavSections$ | async"></sb-side-nav>
        </div>
        <div id="layoutSidenav_content" [ngClass]="{ scrollable: !static }" (click)="closeSideNavIfOpen()">
            <main>
                <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
            </main>
            <sb-footer></sb-footer>
        </div>
    </div>
</span>
<ng-template #defaultContent>
    <ng-content></ng-content>
</ng-template>
<ng-template let-modal #modalInitial>
    <div class="modal-body px-5">
        <h1 class="text-center text-purple mb-5 pt-5"><strong>Bienvenido a tu comercio Fina</strong></h1>
        <h3 class="text-center mb-5">Tu cuenta esta vencida. Para poder continuar con tu comercio debes registrar tu pago.</h3>
        <h5 class="text-center px-5">Si ya realizaste el pago o estás por realizarlo, puedes registrarlo haciendo click en el boton "Registrar pago".</h5>
    </div>
    <div class="modal-footer border-top-0 pb-5">
        <div class="box-btn-cancel">
            <fina-form-pay-plan></fina-form-pay-plan>
        </div>
    </div>
</ng-template>
