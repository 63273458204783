<button
    *ngIf="!dataToUpdate && (userService.user$ | async)?.permissions?.FINANCIAL_SUMMARY?.includes('create-edit-fixes-variables-expenses')"
    class="btn btn-add px-5"
    (click)="analyticsService.analyticsCapture('click_add_expense', { lugar: location }); open(modalAddFixedVariableExpense, { size: 'md', centered: true })"
    [ngClass]="{ 'px-1': compactMode }"
>
    Agregar gasto
</button>

<div *ngIf="dataToUpdate && (userService.user$ | async)?.permissions?.FINANCIAL_SUMMARY?.includes('create-edit-fixes-variables-expenses')">
    <button *ngIf="formData.type === 'fixed'" class="btn w-100 btn-purple mb-2" (click)="open(modalMessageEdit, { size: 'lg', centered: true })">Editar</button>
    <button *ngIf="formData.type === 'variable' || formData.type === 'fixed'" class="btn w-100 btn-danger" (click)="open(modalDeleteFixedVariableExpense, { size: 'md', centered: true })">
        Eliminar
    </button>
</div>

<form #formFixedVariableExpenses="ngForm">
    <ng-template #modalAddFixedVariableExpense let-modal>
        <div class="modal-header">
            <div class="modal-title">{{ dataToUpdate ? 'Editar' : 'Agregar' }} gasto</div>
            <div class="box-btn-close-modal">
                <button (click)="modal.close('Close Click')" class="btn-close-modal rounded-circle" type="button" data-dismiss="modal" aria-label="Close">
                    <img src="assets/icons/svg/icon-btn-close-modal.svg" alt="Cerrar formulario de gastos fijos/variables" />
                </button>
            </div>
        </div>
        <div class="modal-body">
            <!-- Campo para el tipo de gasto -->
            <div class="row pb-3">
                <div class="col-12 col-md-6">
                    <div
                        class="card py-3 ps-3 rounded-3"
                        [ngClass]="{
                            'shadow-none': formData.type !== 'variable',
                            'shadow-sm': formData.type === 'variable',
                            'disabled-card': dataToUpdate
                        }"
                        (click)="!dataToUpdate && onChangeType('variable')"
                    >
                        <div class="form-check box-input-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="checkType"
                                [checked]="formData.type === 'variable'"
                                id="checkTypeVariable"
                                (change)="!dataToUpdate && onChangeType('variable')"
                                [disabled]="dataToUpdate"
                            />
                            <label class="form-check-label input-label mb-0" for="checkTypeVariable">
                                <strong>Gasto Variable</strong>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div
                        class="card py-3 ps-3 rounded-3"
                        [ngClass]="{
                            'shadow-none': formData.type !== 'fixed',
                            'shadow-sm': formData.type === 'fixed'
                        }"
                        (click)="!dataToUpdate && onChangeType('fixed')"
                    >
                        <div class="form-check box-input-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="checkType"
                                [checked]="formData.type === 'fixed'"
                                id="checkTypeFixed"
                                (change)="!dataToUpdate && onChangeType('fixed')"
                                [disabled]="dataToUpdate"
                            />
                            <label class="form-check-label input-label mb-0" for="checkTypeFixed">
                                <strong>Gasto Fijo</strong>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Campo Nombre -->
                <div class="col mb-3 box-field">
                    <label class="input-label" for="name">Nombre del gasto</label>
                    <input
                        class="form-control box-input"
                        type="text"
                        #name="ngModel"
                        id="name"
                        name="name"
                        [(ngModel)]="formData.name"
                        required
                        autocomplete="off"
                        [ngClass]="{ 'is-valid': name.touched && name.valid, 'is-invalid': name.touched && !name.valid }"
                    />
                    <div class="invalid-feedback" *ngIf="name.hasError('required')">Debe asignar un nombre para el gasto.</div>
                </div>
            </div>
            <!-- Campo Monto -->
            <div class="row">
                <div class="col mb-3 box-field">
                    <label class="input-label" for="amount">Monto</label>
                    <div class="input-group">
                        <input
                            class="form-control box-input-span"
                            type="number"
                            #amount="ngModel"
                            id="amount"
                            name="amount"
                            [(ngModel)]="formData.amount"
                            required
                            min="0"
                            (change)="onChangeAmount()"
                            [ngClass]="{ 'is-valid': amount.touched && amount.valid, 'is-invalid': amount.touched && !amount.valid }"
                        />
                        <span class="input-group-text form-control-solid span-input">{{ '$' }}</span>
                        <div class="invalid-feedback" *ngIf="amount.hasError('required')">Debe asignar un monto.</div>
                    </div>
                </div>
                <!-- Fecha del registro -->
                <div class="col-sm-6 mb-3 box-field" *ngIf="formData.type === 'variable'">
                    <label for="registryDate" class="input-label">Fecha del pago</label>
                    <div class="input-group">
                        <input
                            class="form-control input-calendar"
                            oninput="validity.valid || (value=currentDate)"
                            #registryDate="ngModel"
                            id="registryDate"
                            name="registryDate"
                            ngbDatepicker
                            [(ngModel)]="formData.registryDate"
                            #d="ngbDatepicker"
                            autocomplete="off"
                            [ngClass]="{ 'is-invalid': registryDate.touched && !registryDate.valid }"
                            [maxDate]="currentDate"
                        />
                        <button class="btn calendar" (click)="d.toggle()" type="button">
                            <img src="assets/icons/svg/calendar.svg" alt="Icono para seleccionar una fecha" class="btn p-0 icon-calendar" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="formData.type === 'fixed'">
                <!-- Campo para elegir el día del mes a pagar -->
                <div class="col-sm-6 mb-3 box-field">
                    <label class="input-label" for="paymentDate">Dia del mes a pagar (Opcional)</label>
                    <div class="input-group">
                        <input
                            class="form-control input-calendar"
                            placeholder="yyyy-mm-dd"
                            oninput="validity.valid || (value='')"
                            #paymentDate="ngModel"
                            id="paymentDate"
                            name="paymentDate"
                            ngbDatepicker
                            [(ngModel)]="formData.paymentDate"
                            #d="ngbDatepicker"
                            autocomplete="off"
                            [ngClass]="{ 'is-invalid': paymentDate.touched && !paymentDate.valid }"
                            [minDate]="currentDate"
                        />
                        <button class="btn calendar" (click)="d.toggle()" type="button">
                            <img src="assets/icons/svg/calendar.svg" alt="Icono para seleccionar una fecha" class="btn p-0 icon-calendar" />
                        </button>
                    </div>
                </div>
                <!-- Campo para elegir la frecuencia de pago -->
                <div class="col-sm-6 mb-4 box-field">
                    <label for="paymentFrequency" class="input-label">Frecuencia de pago (Opcional)</label>
                    <div class="input-group">
                        <select
                            class="input-group form-select form-control box-input"
                            id="paymentFrequency"
                            name="paymentFrequency"
                            #paymentFrequency="ngModel"
                            [(ngModel)]="formData.paymentFrequency"
                            [ngClass]="{ 'is-invalid': paymentFrequency.touched && !paymentFrequency.valid }"
                        >
                            <option value="semanal">Semanal</option>
                            <option value="quincenal">Quincenal</option>
                            <option selected value="mensual">Mensual</option>
                            <option value="bimestral">Bimestral</option>
                            <option value="trimestral">Trimestral</option>
                            <option value="semestral">Semestral</option>
                            <option value="anual">Anual</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- Cuenta bancaria a debitar -->
            <div class="row" *ngIf="formData.type === 'variable'">
                <div class="col mb-3 box-field">
                    <label for="selectedBankAccount" class="input-label">Cuenta a debitar</label>
                    <div class="input-group">
                        <select
                            class="input-group form-select form-control rounded box-input"
                            id="selectedBankAccount"
                            name="selectedBankAccount"
                            #selectedBankAccount="ngModel"
                            [(ngModel)]="bankAccountData.selectedBankAccount"
                            required
                            (change)="onChangeBankAccount()"
                            [ngClass]="{
                                'is-valid': selectedBankAccount.touched && selectedBankAccount.valid,
                                'is-invalid': selectedBankAccount.touched && !selectedBankAccount.valid
                            }"
                        >
                            <option selected value="">Seleccione...</option>
                            <option *ngFor="let bas of bankAccounts" [value]="bas._id">{{ bas.name }}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="selectedBankAccount.hasError('required')">Debe seleccionar una cuenta bancaria a debitar.</div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedBankAccount?.currency === 'VES' && formData.type === 'variable'" class="row">
                <!-- Tasa de cambio a usar -->
                <div class="col-sm-6 mb-3 box-field">
                    <label for="exchangeRate" class="input-label">Tasa de cambio</label>
                    <div class="input-group">
                        <input
                            class="form-control box-input-span"
                            type="number"
                            #exchangeRate="ngModel"
                            id="exchangeRate"
                            name="exchangeRate"
                            [(ngModel)]="bankAccountData.exchangeRate"
                            min="0"
                            required
                            (change)="onChangeExchangeRate()"
                            [ngClass]="{
                                'is-valid': exchangeRate.touched && exchangeRate.valid,
                                'is-invalid': (exchangeRate.touched && !exchangeRate.valid) || bankAccountData.exchangeRate <= 0
                            }"
                        />
                        <span class="input-group-text form-control-solid span-input">{{ 'Bs./$' }}</span>
                        <div class="invalid-feedback" *ngIf="exchangeRate.hasError('required')">Debe establecer una tasa de cambio.</div>
                        <div class="invalid-feedback" *ngIf="bankAccountData.exchangeRate <= 0">Tasa de cambio inválida.</div>
                    </div>
                    <div class="text-exchange-rate">{{ isBCVRate() ? 'Tasa Oficial BCV' : 'Tasa preferencial' }}</div>
                </div>
                <!-- Equivalencia en Bs. -->
                <div class="col-sm-6 mb-3 box-field">
                    <label for="VESPayment" class="input-label">Equivalente en Bolívares</label>
                    <div class="input-group">
                        <input
                            class="form-control box-input-span"
                            type="number"
                            #VESPayment="ngModel"
                            id="VESPayment"
                            name="VESPayment"
                            min="0"
                            required
                            disabled
                            [(ngModel)]="bankAccountData.bankAccountVESPayment"
                            [ngClass]="{
                                'is-valid': VESPayment.touched && VESPayment.valid,
                                'is-invalid': (VESPayment.touched && !VESPayment.valid) || bankAccountData.bankAccountVESPayment > selectedBankAccount?.balance
                            }"
                        />
                        <span class="input-group-text form-control-solid span-input">{{ 'Bs./$' }}</span>
                        <div class="invalid-feedback" *ngIf="bankAccountData.bankAccountVESPayment > selectedBankAccount?.balance">
                            El total a pagar excede el total disponible en la cuenta bancaria.
                        </div>
                    </div>
                </div>
            </div>
            <!-- Campo descripcion -->
            <div class="row" *ngIf="formData.type === 'variable'">
                <div class="col-sm-12 mb-3 box-field">
                    <label class="input-label" for="description">Descripción</label>
                    <textarea class="form-control box-input" type="text" #description="ngModel" id="description" name="description" [(ngModel)]="formData.description" autocomplete="off"></textarea>
                </div>
            </div>
            <!-- Botón para confirmar el ingreso -->
            <div class="box-btn-submit">
                <button
                    class="btn btn-submit"
                    [disabled]="formFixedVariableExpenses.invalid || loading || isBankAccountInvalid() || (bankAccountData.currency === 'VES' && bankAccountData.exchangeRate <= 0)"
                    (click)="
                        save(); analyticsService.analyticsCapture(formData.type === 'variable' ? 'add_variable_expense' : 'add_fixed_expense'); analyticsService.analyticsCapture('confirm_expense')
                    "
                >
                    {{ dataToUpdate === null ? 'Confirmar gasto' : 'Guardar cambios' }}
                </button>
            </div>
        </div>
    </ng-template>
</form>

<ng-template #modalMessageEdit let-modal>
    <div class="modal-header">
        <div class="modal-title">Editar gasto</div>
        <div class="box-btn-close-modal">
            <button (click)="modal.close('Close Click')" class="btn-close-modal rounded-circle" type="button" data-dismiss="modal" aria-label="Close">
                <img src="assets/icons/svg/icon-btn-close-modal.svg" class="" alt="Cerrar formulario de editar gasto" />
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="text-center">
                ¿Seguro que desea Editar este Gasto?. Esta edita la proxima fecha de pago y si el monto es modificado se modificaran los pagos "Expirados". Si no quieres modificar Gastos sin pagar te
                recomendamos crear un nuevo registro.
            </div>
        </div>
        <!-- Botón para eliminar el ingreso -->
        <div class="box-btn-submit">
            <button class="btn btn-purple" (click)="open(modalAddFixedVariableExpense, { size: 'lg', centered: true })">Confirmar</button>
        </div>
    </div>
</ng-template>

<ng-template #modalDeleteFixedVariableExpense let-modal>
    <div class="modal-header">
        <div class="modal-title">Eliminar gasto</div>
        <div class="box-btn-close-modal">
            <button (click)="modal.close('Close Click')" class="btn-close-modal rounded-circle" type="button" data-dismiss="modal" aria-label="Close">
                <img src="assets/icons/svg/icon-btn-close-modal.svg" class="" alt="Cerrar formulario de eliminar gasto" />
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="text-center">{{ dataToUpdate.pendingAccount ? 'Este gasto pertenece a una cuenta por pagar' : '' }} ¿Seguro que desea eliminar este Gasto?. Esta acción es irreversible.</div>
        </div>
        <!-- Botón para eliminar el ingreso -->
        <div class="box-btn-submit">
            <button class="btn btn-danger" [disabled]="formFixedVariableExpenses.invalid || loading || isBankAccountInvalid()" (click)="delete()">Confirmar</button>
        </div>
    </div>
</ng-template>
