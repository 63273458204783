<div class="progress">
    <div
        class="progress-bar"
        [ngClass]="progressBarClasses"
        role="progressbar"
        [ngStyle]="{ width: value + '%' }"
        aria-valuenow="value/100"
        aria-valuemin="0"
        aria-valuemax="100"
    >
        <ng-content></ng-content>
    </div>
</div>
