import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaginationService } from '@common/services/pagination.service';
import { BankAccountRegistry } from '@modules/bank-accounts/models';
import { PaymentMethod } from '@modules/commerce/models';
import { SortEvent } from '@modules/tables/directives';
import { environment } from 'environments/environment';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { BankAccount } from '../models';

@Injectable({
    providedIn: 'root',
})
export class BankAccountsService {
    totalBankAccounts: number = 0;
    loading: boolean = false;

    API_URL = environment.API_URL;
    _bankAccounts$: BehaviorSubject<BankAccount[]> = new BehaviorSubject<BankAccount[]>([]);
    _bankAccountHistory$: BehaviorSubject<BankAccountRegistry[]> = new BehaviorSubject<BankAccountRegistry[]>(null);
    _bankAccountsHistory$: BehaviorSubject<BankAccountRegistry[]> = new BehaviorSubject<BankAccountRegistry[]>(null);
    _selectedBankAccountHistory$: BehaviorSubject<BankAccountRegistry> = new BehaviorSubject<BankAccountRegistry>(null);
    _selectedBankAccount$: BehaviorSubject<BankAccount> = new BehaviorSubject<BankAccount>(null);
    _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    _bankAccountId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _selectedCapitalData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // paginacion
    month: string = moment().format('MM/YYYY');
    pageSize: number = 50;
    currentPage: number = 1;
    totalPages: number = 1;
    totalDocs: number = 0;
    sortedColumn: string = 'updatedAt';
    sortedDirection: string = 'desc';
    useScore: boolean = false;
    searchControl = new FormControl('');
    fromDate: string = moment().startOf('month').toISOString();
    toDate: string = new Date().toISOString();

    // PAGINATION
    fullData: any[] = [];
    // totalPages: number = 0;
    pageNumber: number = 1;

    constructor(private http: HttpClient, private paginationService: PaginationService) {}

    get bankAccounts$() {
        return this._bankAccounts$;
    }

    get selectedCapitalData$() {
        return this._selectedCapitalData$;
    }

    get bankAccountId$() {
        return this._bankAccountId$;
    }

    set bankAccountId(id: string) {
        this._bankAccountId$.next(id);
    }

    set bankAccounts(array: BankAccount[]) {
        this._bankAccounts$.next([...array]);
    }

    get bankAccountHistory$() {
        return this._bankAccountHistory$;
    }

    get bankAccountsHistory$() {
        return this._bankAccountsHistory$;
    }

    set bankAccountHistory(array: BankAccountRegistry[]) {
        this._bankAccountHistory$.next([...array]);
    }

    set bankAccountsHistory(array: BankAccountRegistry[]) {
        this._bankAccountsHistory$.next([...array]);
    }

    get selectedBankAccountHistory$() {
        return this._selectedBankAccountHistory$;
    }

    set selectedBankAccountHistory(bar: BankAccountRegistry) {
        this._selectedBankAccountHistory$.next({ ...bar });
    }

    set selectedCapitalData(capitalData: any) {
        this._selectedCapitalData$.next({ ...capitalData });
    }

    get selectedBankAccount$() {
        return this._selectedBankAccount$;
    }

    set selectedBankAccount(ba: BankAccount) {
        this._selectedBankAccount$.next({ ...ba });
    }

    getBankAccounts$(): Observable<{}> {
        return this.http.get<any>(`${this.API_URL}/bank-accounts`);
    }

    createBankAccount$(dataBankAccount: BankAccount, dataPaymentMethods: PaymentMethod[]): Observable<{}> {
        return this.http.post<any>(`${this.API_URL}/bank-accounts`, {
            bankAccount: { ...dataBankAccount },
            paymentMethods: [...dataPaymentMethods],
        });
    }

    updatePaymentMethodsInBankAccount$(bankAccountId: string, dataBankAccount: BankAccount, dataPaymentMethods: PaymentMethod[]): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/bank-accounts/${bankAccountId}`, {
            bankAccount: { ...dataBankAccount },
            paymentMethods: [...dataPaymentMethods],
        });
    }

    deleteBankAccount$(_id: string): Observable<{}> {
        return this.http.delete<any>(`${this.API_URL}/bank-accounts/${_id}`);
    }

    deleteRegistry$(_id: string): Observable<{}> {
        return this.http.delete<any>(`${this.API_URL}/bank-accounts/registry/${_id}`);
    }

    transferBetweenAccounts$(data: any): Observable<{}> {
        return this.http.post<any>(`${this.API_URL}/bank-accounts/transfer`, { ...data });
    }

    withdrawFromBankAccount$(data: any): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/bank-accounts/withdraw/${data.bankAccountId}`, {
            amount: data.amount,
            concept: data.concept,
        });
    }

    depositInBankAccount$(data: any): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/bank-accounts/deposit/${data.bankAccountId}`, {
            amount: data.amount,
            concept: data.concept,
        });
    }

    getBankAccountHistory$(bankAccountId: string): Observable<{}> {
        let params = new HttpParams()
            .set('currentPage', this.currentPage)
            .set('pageSize', this.pageSize)
            .set('search', this.searchControl.value)
            .set('sortedColumn', this.sortedColumn)
            .set('sortedDirection', this.sortedDirection)
            .set('fromDate', this.fromDate)
            .set('toDate', this.toDate)
            .set('useScore', this.useScore);
        return this.http.get<any>(`${this.API_URL}/bank-accounts/history/${bankAccountId}/?bankAccountId=${bankAccountId}`, { params });
    }

    changePage(page: number) {
        this.pageNumber += page;

        if (this.pageNumber > this.totalPages) {
            this.pageNumber = this.totalPages;
        }
        if (this.pageNumber < 1) {
            this.pageNumber = 1;
        }

        this.bankAccountHistory = this.paginationService.paginateData(this.fullData, this.pageNumber);
    }

    getBankAccountsHistory$(): Observable<{}> {
        let params = new HttpParams()
            .set('currentPage', this.currentPage)
            .set('pageSize', this.pageSize)
            .set('search', this.searchControl.value)
            .set('sortedColumn', this.sortedColumn)
            .set('sortedDirection', this.sortedDirection)
            .set('useScore', this.useScore)
            .set('date', this.month);
        return this.http.get<any>(`${this.API_URL}/bank-accounts/history`, { params });
    }

    // Obtener data de resumen cuenta bancarias
    getLastCapitalData$(): Observable<any[]> {
        return this.http.get<any[]>(`${this.API_URL}/bank-accounts/capital`);
    }

    nextPage(type: string) {
        if (this.currentPage < this.totalPages) {
            this.loading = true;
            this.currentPage++;
            this.paginate(type);
        }
    }

    goToPage(page: number, type: string) {
        if (this.currentPage !== page) {
            this.loading = true;
            this.currentPage = page;
            this.paginate(type);
        }
    }

    previousPage(type: string) {
        if (this.currentPage > 1) {
            this.loading = true;
            this.currentPage--;
            this.paginate(type);
        }
    }

    search(type: string) {
        this.loading = true;
        this.currentPage = 1;
        this.useScore = true;
        this.paginate(type);
    }

    onSort({ column, direction }: SortEvent, type: string) {
        this.sortedColumn = column;
        this.sortedDirection = direction;
        this.loading = true;
        this.currentPage = 1;
        this.useScore = !(column && direction);
        this.paginate(type);
    }

    private paginate(type: string) {
        if (type === 'bankAccount') {
            this.getBankAccountHistory$(this.bankAccountId$.value).subscribe({
                next: (res: any) => {
                    this.bankAccountHistory = [...res.data];
                    this.totalPages = res.totalPages;
                    this.totalDocs = res.totalDocs;
                    this.loading = false;
                },
                error: err => {
                    console.error(err);
                    this.loading = false;
                },
            });
        } else if (type === 'bankAccounts') {
            this.getBankAccountsHistory$().subscribe({
                next: (res: any) => {
                    this.bankAccountsHistory = [...res.data];
                    this.totalPages = res.totalPages;
                    this.totalDocs = res.totalDocs;
                    this.loading = false;
                },
                error: err => {
                    console.error(err);
                    this.loading = false;
                },
            });
        }
    }
}
