import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'sb-layout-error',
    templateUrl: './layout-error.component.html',
    styleUrls: ['layout-error.component.scss'],
})
export class LayoutErrorComponent {
    constructor() {}
}
