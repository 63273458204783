<div class="pagination">
    <button (click)="onPrev()" [disabled]="currentPage === 1">
        <fa-icon [icon]="['fas', 'angle-left']" [styles]="{ 'font-size': '10px' }"></fa-icon>
    </button>
    <button (click)="onGoToPage(1)" [class.active]="currentPage === 1">1</button>
    <span *ngIf="currentPage > 3"><b>...</b></span>
    <button *ngFor="let page of pages" (click)="onGoToPage(page)" [class.active]="currentPage === page">{{ page }}</button>
    <span *ngIf="currentPage < totalPages - 2"><b>...</b></span>
    <button *ngIf="totalPages > 1" (click)="onGoToPage(totalPages)" [class.active]="currentPage === totalPages">{{ totalPages }}</button>
    <button (click)="onNext()" [disabled]="currentPage === totalPages">
        <fa-icon [icon]="['fas', 'angle-right']" [styles]="{ 'font-size': '10px' }"></fa-icon>
    </button>
</div>
