import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
import { AuthService } from '@common/services';
import { filter } from 'rxjs/operators';

import { VersionCheckService } from './services/version-check.service';

@Component({
    selector: 'app-root',
    template: `
        <div *ngIf="updateAvailable" class="update-banner">
            Una nueva versión esta disponible, refresca la página para que Fina funcione correctamente.
            <button class="refresh-button" (click)="reloadApp()">Refrescar</button>
        </div>
        <sbpro-toast-view class="ngb-toasts" aria-live="polite" aria-atomic="true"></sbpro-toast-view>
        <fina-news-view></fina-news-view>
        <router-outlet></router-outlet>
    `,
    styles: [
        `
            .update-banner {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                background: #ff9800;
                color: white;
                text-align: center;
                padding: 10px;
                z-index: 9999;
            }

            .refresh-button {
                background: white;
                color: #ff9800;
                border: none;
                padding: 4px 12px;
                margin-left: 8px;
                border-radius: 12px;
                cursor: pointer;
            }

            .refresh-button:hover {
                background: #f5f5f5;
            }
        `,
    ],
})
export class AppComponent implements OnInit {
    updateAvailable = false;

    constructor(public router: Router, private titleService: Title, private authService: AuthService, private versionCheckService: VersionCheckService) {
        this.router.events.pipe(filter(event => event instanceof ChildActivationEnd)).subscribe(event => {
            let snapshot = (event as ChildActivationEnd).snapshot;

            while (snapshot.firstChild !== null) {
                snapshot = snapshot.firstChild;
            }
            this.titleService.setTitle(snapshot.data.title || 'FINA Business');
        });
    }

    ngOnInit(): void {
        this.authService.autoLogin();
        document.body.classList.add('notranslate');
        this.versionCheckService.initVersionCheck('/version.json').subscribe(updateAvailable => {
            this.updateAvailable = updateAvailable;
            if (updateAvailable) {
                // Optionally force update immediately
                // this.reloadApp();
            }
        });
    }

    reloadApp() {
        // Clear cache and reload
        window.location.reload();
    }
}
