import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { NewsService } from '@common/services/news.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fina-news-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './news-view.component.html',
    styleUrls: ['news-view.component.scss'],
})
export class NewsViewComponent {
    @ViewChild('modaNews') modaNews: TemplateRef<any>;

    constructor(public newsService: NewsService, private modalService: NgbModal) {}

    ngAfterViewInit() {
        this.newsService.dataNews$.subscribe({
            next: data => {
                if (data) {
                    this.modalService.open(this.modaNews, { size: 'xl', centered: true }).result.finally(() => {
                        this.newsService.onHideModal();
                    });
                }
            },
        });
    }
}
