import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PaginationService {
    pageSize = 50;

    public paginateData(data: any[], pageNumber: number): any[] {
        const startIndex = (pageNumber - 1) * this.pageSize;
        const endIndex = pageNumber * this.pageSize;

        const totalPages = this.getPages(data);

        if (pageNumber < 1) return data.slice(0, this.pageSize);
        if (pageNumber > totalPages) return data.slice(totalPages * this.pageSize, endIndex);

        return data.slice(startIndex, endIndex);
    }

    public getPages(data: any[]): number {
        return Math.ceil(data.length / this.pageSize);
    }
}
