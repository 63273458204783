import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentMethod } from '@modules/commerce/models';
import { environment } from 'environments/environment';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { FixedVariableExpense } from '../models';

interface SummaryFVEDataPerMonth {
    difFE?: number;
    difPercentFE?: number;
    difPercentTotal?: number;
    difPercentVE?: number;
    difTotal?: number;
    difVE?: number;
    fixedExpenses?: number;
    totalExpenses?: number;
    variableExpenses?: number;
    month?: number;
}

interface dataFVEPerMonth {
    fixedExpenses: FixedVariableExpense[];
    variableExpenses: FixedVariableExpense[];
    month: number;
}

@Injectable({
    providedIn: 'root',
})
export class FixedVariableExpensesService {
    API_URL = environment.API_URL;

    // Observables para contener la información de los resultados de gastos fijos/variables por mes
    private _summaryFVEPerMonth$: BehaviorSubject<SummaryFVEDataPerMonth[]> = new BehaviorSubject<SummaryFVEDataPerMonth[]>(null);
    private _selectedSummaryFVEPerMonth$: BehaviorSubject<SummaryFVEDataPerMonth> = new BehaviorSubject<SummaryFVEDataPerMonth>(null);

    // Observables para contener la data de gastos fijos/variables por mes (datos a mostrar en las tablas)
    private _dataFVEPerMonth$: BehaviorSubject<dataFVEPerMonth[]> = new BehaviorSubject<dataFVEPerMonth[]>(null);
    private _selectedDataFVEPerMonth$: BehaviorSubject<dataFVEPerMonth> = new BehaviorSubject<dataFVEPerMonth>(null);

    // obtener metodos de pago
    private _paymentMethods$: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<PaymentMethod[]>([]);
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _selectedYear$: BehaviorSubject<number> = new BehaviorSubject<number>(new Date().getFullYear());

    constructor(private http: HttpClient) {}

    get summaryFVEPerMonth$() {
        return this._summaryFVEPerMonth$;
    }

    get selectedSummaryFVEPerMonth$() {
        return this._selectedSummaryFVEPerMonth$;
    }

    get dataFVEPerMonth$() {
        return this._dataFVEPerMonth$;
    }

    get selectedDataFVEPerMonth$() {
        return this._selectedDataFVEPerMonth$;
    }

    set summaryFVEPerMonth(array: SummaryFVEDataPerMonth[]) {
        this._summaryFVEPerMonth$.next([...array]);
    }

    set selectedSummaryFVEPerMonth(summaryData: SummaryFVEDataPerMonth) {
        this._selectedSummaryFVEPerMonth$.next({ ...summaryData });
    }

    set dataFVEPerMonth(array: dataFVEPerMonth[]) {
        this._dataFVEPerMonth$.next([...array]);
    }

    set selectedDataFVEPerMonth(fveData: dataFVEPerMonth) {
        this._selectedDataFVEPerMonth$.next({ ...fveData });
    }

    get paymentMethods$() {
        return this._paymentMethods$;
    }

    set paymentMethods(paymentMethods: PaymentMethod[]) {
        this._paymentMethods$.next(paymentMethods);
    }

    set selectedYear(year: number) {
        this._selectedYear$.next(year);
    }

    get selectedYear$() {
        return this._selectedYear$;
    }

    getPaymentMethods$() {
        return this.http.get<any>(`${this.API_URL}/payment-methods`);
    }

    createFixedVariableExpense$(data: FixedVariableExpense, bankAccountData: any): Observable<{}> {
        return this.http.post<any>(`${this.API_URL}/fixed-variable-expenses`, {
            ...data,
            bankAccountData,
            year: this.selectedYear$.getValue(),
        });
    }

    updateFixedVariableExpense$(fveId: string, data: FixedVariableExpense, bankAccountData: any): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/fixed-variable-expenses/${fveId}`, {
            ...data,
            bankAccountData,
            year: this.selectedYear$.getValue(),
        });
    }

    deleteFixedVariableExpense$(fveId: string): Observable<{}> {
        return this.http.delete<any>(`${this.API_URL}/fixed-variable-expenses/${fveId}`, {
            params: { year: this.selectedYear$.getValue() },
        });
    }

    getFixedVariableExpenses$(): Observable<{}> {
        let params = new HttpParams().set('year', this.selectedYear$.getValue());
        return this.http.get<any>(`${this.API_URL}/fixed-variable-expenses`, { params });
    }

    getVariableExpensesByMonth$(month: number): Observable<{}> {
        let params = new HttpParams().set('month', month).set('year', this.selectedYear$.getValue());

        return this.http.get<any>(`${this.API_URL}/fixed-variable-expenses/variable-by-month`, { params });
    }

    getFixedVariableExpensesPaid$(month: string): Observable<{}> {
        let params = new HttpParams().set('month', month).set('year', this.selectedYear$.getValue());
        return this.http.get<any>(`${this.API_URL}/fixed-variable-expenses/paid`, { params });
    }

    updateFixedExpensePaymentDate$(): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/fixed-variable-expenses/update-payment-date`, { year: this.selectedYear$.getValue() });
    }

    payFixedVariableExpense$(fveId: string, date: string, bankAccountData: any): Observable<{}> {
        return this.http.put<any>(`${this.API_URL}/fixed-variable-expenses/pay/${fveId}`, {
            date,
            bankAccountData,
            year: this.selectedYear$.getValue(),
        });
    }

    getFixedVariableExpenses(month?: any) {
        const currentMonth = month >= 0 ? month || 0 : new Date().getMonth();

        forkJoin([this.getFixedVariableExpenses$(), this.getVariableExpensesByMonth$(currentMonth)]).subscribe({
            next: ([res, variableExpenses]: any[]) => {
                this.summaryFVEPerMonth = [...res.summaryData];
                this.selectedSummaryFVEPerMonth = res.summaryData[currentMonth];
                this.dataFVEPerMonth = [...res.fveData];
                this.selectedDataFVEPerMonth = {
                    ...res.fveData[0],
                    variableExpenses: variableExpenses.data,
                };
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }
}
